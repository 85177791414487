
import { useIsAuthenticated } from "@azure/msal-react";

import { Col,  Menu, MenuProps, Row,  } from 'antd';
import { Link } from "react-router-dom";
import { SignInButton } from "./Button/signInButton";
import { SignOutButton } from "./Button/signOutButton";
import logo from "../../Images/logo.png";

export const PageLayout = (props:any) => {
    const isAuthenticated = useIsAuthenticated();
    const items: MenuProps['items'] = [
      {
        label:  (
          <Link to='./userDetail' >
            Requested Users
          </Link>
        ),
        key: 'requestedUsers',
       
      },
  ]
  

    return (
    <div className="container">
        <div className="site-header__container">
        <Row justify="space-between" align="middle" style={{marginLeft: '2rem', flexWrap: 'nowrap', width: '100%', marginRight: '2rem'}}>
             <div className="site-header"><img src={logo} alt='logo' className="Header__logo" /></div>
            
           
            <Col flex="1 0 auto">
            <Menu className="nav-menu" mode="horizontal"  items={items} />
            </Col>
            <Col flex="0 1 auto">
            
              <div className='title'> {isAuthenticated ? <SignOutButton /> : <SignInButton />}</div>
            </Col>
          </Row>
            </div>
            {props.children}
        </div>
    );
};