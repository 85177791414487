import { isProdEnv, isDevEnv } from './environments';
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import axios from "axios"
import { appInsights } from "../applicationInsightsService"


export const getRequestedUsers = async function () {

  try {
    const remoteTarget = `/api/requested-users`
    const apiCall = await axios.get(remoteTarget, {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',

      },
    })

    if (apiCall.status === 200) {
      const requestedUsersList = await apiCall.data
      return requestedUsersList
    }
   isProdEnv && appInsights.trackException({ error: new Error('error retrieving api data'), severityLevel: SeverityLevel.Error });
    return undefined

  } catch (error) {
    isProdEnv && appInsights.trackException({ error: new Error('Error processing request. Check Server logs'), severityLevel: SeverityLevel.Error })

  }

}


export const postNewUser = async function (name: string, surname: string, email: string, company: string, RBU: string, level: string, requestor: string, file?: string) {
  try {
    const remoteTarget = '/api/requested-users'
    const newRequestedUser = {
      name: name,
      surname: surname,
      email: email,
      company: company,
      roles: [level],
      rbu: RBU,
      requester: requestor,
      ndaDocumentId: file
    }
    const apiCall = await axios.post(remoteTarget, JSON.stringify(newRequestedUser), {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
    })

    if (apiCall.status === 200) {
      return apiCall

    }
    isProdEnv && appInsights.trackException({ error: new Error('error retrieving api data'), severityLevel: SeverityLevel.Error });
    return undefined

  } catch (error) {
    isProdEnv && appInsights.trackException({ error: new Error('Error processing request. Check Server logs'), severityLevel: SeverityLevel.Error })
    isDevEnv && console.error(error)

  }
}

export const postNewCompany = async function (name: string, rbu: string) {
  try {
    const remoteTarget = '/api/company'
    const newCompany = {
      name: name,
      rbu: rbu,
    }
    const apiCall = await axios.post(remoteTarget, JSON.stringify(newCompany), {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
    })

    if (apiCall.status === 200) {
      return (apiCall.status)
      
    } 
  
    isProdEnv && appInsights.trackException({ error: new Error('error retrieving api data'), severityLevel: SeverityLevel.Error });
      return apiCall.status
    
  } catch (error:any) {
    isProdEnv && appInsights.trackException({ error: new Error('Error processing request. Check Server logs'), severityLevel: SeverityLevel.Error })
    isDevEnv && console.error(error)
    return error.response.status

  }
}

export const getRequestedUserById = async function (id?: string) {

  try {
    const remoteTarget = `/api/requested-users/${id}`
    const apiCall = await axios.get(remoteTarget, {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',

      },
      
      
    }
   )

    if (apiCall.status === 200) {
      const toDosList = await apiCall.data
      return toDosList

    } 
    isProdEnv && appInsights.trackException({ error: new Error('error retrieving api data'), severityLevel: SeverityLevel.Error });
      return undefined
    
  } catch (error) {
    isProdEnv && appInsights.trackException({ error: new Error('Error processing request. Check Server logs'), severityLevel: SeverityLevel.Error })
    isDevEnv && console.error(error)

  }

}

export const updateExistingUser = async function (name: string, surname: string, email: string, company: string, RBU: string, level: string, requestor: string, id?: string, file?: string) {

  try {
    const updatedUser = {
      name: name,
      surname: surname,
      email: email,
      company: company,
      roles: level,
      rbu: RBU,
      requester: requestor,
      ndaDocumentId: file
    }

    const remoteTarget = `/api/requested-users/${id}`

    const apiCall = await axios.patch(remoteTarget, JSON.stringify(updatedUser), {

      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
    })
    if (apiCall.status === 200) {
      const newTodo = await apiCall.data

      return newTodo
    } 
    isProdEnv &&  appInsights.trackException({ error: new Error('error retrieving api data'), severityLevel: SeverityLevel.Error });
      return undefined
    

  } catch (error) {
    isProdEnv && appInsights.trackException({ error: new Error('Error processing request. Check Server logs'), severityLevel: SeverityLevel.Error })
    isDevEnv && console.error(error)

  }
}

export const getRbuList = async function () {

  try {
    const remoteTarget = `/api/rbus`
    const apiCall = await axios.get(remoteTarget, {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',

      },
    })

    if (apiCall.status === 200) {
      const rBUList = await apiCall.data
      return rBUList
    }
    
    isProdEnv && appInsights.trackException({ error: new Error('error retrieving api data'), severityLevel: SeverityLevel.Error });
      return undefined
    
  } catch (error) {
    isProdEnv && appInsights.trackException({ error: new Error('Error processing request. Check Server logs'), severityLevel: SeverityLevel.Error })
    isDevEnv && console.error(error)

  }

}

export const getCompanyList = async function (rbu: string) {

  try {
    const remoteTarget = `/api/rbus/${rbu}/companies`
    const apiCall = await axios.get(remoteTarget, {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',

      },
    })

    if (apiCall.status === 200) {
      const companiesList = await apiCall.data
      return companiesList
    } 
    isProdEnv && appInsights.trackException({ error: new Error('error retrieving api data'), severityLevel: SeverityLevel.Error });
      return undefined
    
  } catch (error) {
    isProdEnv && appInsights.trackException({ error: new Error('Error processing request. Check Server logs'), severityLevel: SeverityLevel.Error })
    isDevEnv && console.error(error)

  }

}

export const getLevelsList = async function () {

  try {
    const remoteTarget = `/api/levels`
    const apiCall = await axios.get(remoteTarget, {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',

      },
    })

    if (apiCall.status === 200) {
      const levelList = await apiCall.data
      return levelList
    } 
    isProdEnv &&  appInsights.trackException({ error: new Error('error retrieving api data'), severityLevel: SeverityLevel.Error });
      return undefined
    
  } catch (error) {
    isProdEnv && appInsights.trackException({ error: new Error('Error processing request. Check Server logs'), severityLevel: SeverityLevel.Error })
    isDevEnv && console.error(error)

  }

}


export const addApplicationUser = async function (data:any) {
  try {
    const remoteTarget = `/api/requested-users/${data.id}/approve`
    const newApplicationUser = {
      name: data.name,
      surname: data.surname,
      email: data.email,
      company: data.company,
      roles: data.level,
      rbu: data.RBU,
      requester:data.requestor,
      ndaDocumentId: data.ndaDocumentId,
      reviewer: data.reviewer
    }
    const apiCall = await axios.post(remoteTarget, JSON.stringify(newApplicationUser), {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
    })

    if (apiCall.status === 200) {
      
      return apiCall;

    }
    isProdEnv && appInsights.trackException({ error: new Error('error retrieving api data'), severityLevel: SeverityLevel.Error });
      return undefined
    
  } catch (error) {
    isProdEnv && appInsights.trackException({ error: new Error('Error processing request. Check Server logs'), severityLevel: SeverityLevel.Error })
    isDevEnv && console.error(error)

  }
}

export const rejectUser = async function (id:string, reason:string) {
  try {
    const remoteTarget = `/api/requested-users/${id}/reject`
  
    const apiCall = await axios.post(remoteTarget, reason, {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
    })

    if (apiCall.status === 200) {
      const requestedUser = await apiCall
      return requestedUser

    }
    isProdEnv && appInsights.trackException({ error: new Error('error retrieving api data'), severityLevel: SeverityLevel.Error });
      return undefined
    
  } catch (error) {
    isProdEnv && appInsights.trackException({ error: new Error('Error processing request. Check Server logs'), severityLevel: SeverityLevel.Error })
    isDevEnv && console.error(error)

  }
}

export const uploadNDADocument = async function (file: any){
    const remoteTarget = `/api/upload`
    const config = {
      headers: { "content-type": "multipart/form-data" },
      
    };
    try{
    const apiCall =   await axios.post(remoteTarget, file, config)
    if(apiCall.status === 200){
      return apiCall.data
    }
    }catch(err){}

  }


export const downloadNDADocument = async function (ndaDocument: string){
  try{
    const remoteTarget = `/api/download/${ndaDocument}`
    
    const apiCall = await axios.get(remoteTarget, {
      headers: {
        Accept: '*/*',
        'Content-Type': 'blob',
        
      },
      responseType:'blob'
    })
    if (apiCall.status === 200) {
      return apiCall;

    }
   
  }catch (error) {
    isProdEnv && appInsights.trackException({ error: new Error('Error processing request. Check Server logs'), severityLevel: SeverityLevel.Error })
    isDevEnv && console.error(error)

  }
}


